"use client";

import { Link } from "@/lib/i18n";
import NewLinkNave from "../../NewLinkNav/NewLinkNave";
import Logo from "../../Logo/Logo";
import { useEffect, useState } from "react";
import Level from "../../Gamification/Level/Level";
import * as m from "@/paraglide/messages.js";
import {
    availableLanguageTags,
    AvailableLanguageTag,
    languageTag,
    isAvailableLanguageTag,
} from "@/paraglide/runtime";
import { usePathname, useRouter } from "@/lib/i18n";
import Button from "../../Button/Button";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
    redirectForNewLocale,
    updateLanguageForUser,
} from "@/helpers/languageManager";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

export default function SideHeader({
    page,
    session,
    sessionLoading,
    sideNavIsOpen = false,
}) {
    const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);
    const [languagesModalIsOpen, setLanguagesModalIsOpen] = useState(false);
    const pathname = usePathname();
    const router = useRouter();
    const cookies = new Cookies();
    const locale = languageTag();

    const updateLanguage = async (language) => {
        if (language == locale) return;

        if (session && session.user) {
            updateLanguageForUser(language, router, pathname, session); // Save new language for user
            return;
        } else {
            // Check if language is available
            if (isAvailableLanguageTag(language)) {
                redirectForNewLocale(language, router, pathname, cookies, null);
                return;
            }
        }
    };

    const labels = {
        fr: "🇫🇷 Français",
        en: "🇬🇧 English",
        // de: "🇩🇪 Deutsch",
    };

    return (
        <div className="md:w-[230px] w-[290px] h-full bg-[#F9F9F9] dark:bg-[#252837] overflow-y-auto flex flex-col">
            <div className="flex-1">
                {/* Logo */}
                <div className="px-5 pt-5">
                    {/* For light theme */}
                    <Link href="/" className="inline-block">
                        <div className="dark:hidden">
                            <Logo ratio="0.9" />
                        </div>
                        {/* For dark theme */}
                        <div className="hidden dark:block">
                            <Logo ratio="0.9" white />
                        </div>
                    </Link>
                </div>

                {/* Nav */}
                <nav className="p-3">
                    <ul className="flex flex-col">
                        {/* Main */}
                        <li>
                            <NewLinkNave
                                href="/"
                                page={page}
                                name="dashboard"
                                currentSVG={
                                    <path
                                        fill="currentColor"
                                        fillRule="evenodd"
                                        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10m1.956-7.905c-.193.17-.44.268-.932.465c-2.31.924-3.465 1.386-4.124.938a1.5 1.5 0 0 1-.398-.398c-.448-.66.014-1.814.938-4.124c.197-.493.295-.74.465-.933c.043-.049.09-.095.139-.138c.193-.17.44-.268.932-.465c2.31-.924 3.464-1.386 4.124-.938a1.5 1.5 0 0 1 .398.398c.448.66-.014 1.814-.938 4.124c-.197.493-.295.739-.465.932c-.043.05-.09.096-.139.139"
                                        clipRule="evenodd"
                                    ></path>
                                }
                                notCurrentSVG={
                                    <path
                                        fill="currentColor"
                                        fillRule="evenodd"
                                        d="M12 2.75a9.25 9.25 0 1 0 0 18.5a9.25 9.25 0 0 0 0-18.5M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12S17.937 22.75 12 22.75S1.25 17.937 1.25 12m12.599-2.819c-.605.16-1.42.485-2.595.955c-.541.217-.642.268-.716.333a.758.758 0 0 0-.07.069c-.064.074-.115.175-.332.716c-.47 1.175-.794 1.99-.955 2.595c-.167.63-.085.79-.059.83a.75.75 0 0 0 .2.198c.038.027.199.108.829-.059c.605-.16 1.42-.484 2.594-.954c.542-.217.643-.268.717-.333l.494.564l-.494-.564a.733.733 0 0 0 .07-.07l.563.495l-.564-.494c.065-.074.116-.175.333-.717c.47-1.174.794-1.99.954-2.594c.168-.63.086-.791.06-.83a.75.75 0 0 0-.2-.199c-.038-.026-.2-.108-.83.06m-.384-1.45c.69-.183 1.436-.271 2.057.15c.234.16.437.362.596.597c.422.621.333 1.367.15 2.057c-.186.704-.546 1.605-.991 2.717l-.02.05l-.034.084c-.163.41-.308.772-.564 1.065a2.248 2.248 0 0 1-.208.208c-.293.256-.655.4-1.065.564l-.083.033l-.279-.696l.279.697l-.05.02c-1.113.445-2.014.805-2.718.991c-.69.183-1.436.272-2.057-.15l.422-.62l-.422.62a2.25 2.25 0 0 1-.596-.596c-.422-.621-.334-1.368-.15-2.057c.186-.705.546-1.605.991-2.717l.02-.05l.034-.085c.163-.41.307-.771.564-1.064c.065-.074.134-.143.208-.208c.293-.257.655-.401 1.064-.564l.084-.034l.05-.02c1.113-.445 2.013-.805 2.718-.992"
                                        clipRule="evenodd"
                                    ></path>
                                }
                                sideNavIsOpen={sideNavIsOpen}
                            >
                                <div>{m.SideHeader_dashboard()}</div>
                            </NewLinkNave>
                        </li>

                        {/* SECTION */}
                        <li className="after-line">
                            <div>{m.SideHeader_doneYourself()}</div>
                        </li>

                        {/* Done Yourself */}
                        <li>
                            <NewLinkNave
                                href="/courses"
                                page={page}
                                name="courses"
                                currentSVG={
                                    <>
                                        <path
                                            fill="currentColor"
                                            d="M14.217 3.5a5.17 5.17 0 0 0-4.434 0L3.092 6.637c-1.076.504-1.357 1.927-.842 2.91V14.5a.75.75 0 0 0 1.5 0v-3.828L9.783 13.5a5.17 5.17 0 0 0 4.434 0l6.691-3.137c1.456-.682 1.456-3.044 0-3.726z"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            d="M5 12.915v3.71c0 1.008.503 1.952 1.385 2.44C7.854 19.88 10.204 21 12 21c1.796 0 4.146-1.121 5.615-1.934c.882-.489 1.385-1.433 1.385-2.44v-3.711l-4.146 1.944a6.67 6.67 0 0 1-5.708 0z"
                                        ></path>
                                    </>
                                }
                                notCurrentSVG={
                                    <path
                                        fill="currentColor"
                                        fillRule="evenodd"
                                        d="M9.464 2.82a5.92 5.92 0 0 1 5.072 0l6.69 3.138C22.28 6.45 22.75 7.522 22.75 8.5c0 .978-.47 2.05-1.523 2.543l-1.477.692v4.89c0 1.253-.628 2.463-1.772 3.097c-.752.416-1.74.917-2.78 1.316c-1.027.394-2.164.712-3.198.712c-1.034 0-2.171-.318-3.199-.712c-1.04-.399-2.027-.9-2.779-1.316c-1.144-.634-1.772-1.844-1.772-3.097v-4.89l-1.477-.693l-.023-.01V14a.75.75 0 0 1-1.5 0V8.5c0-.978.471-2.05 1.523-2.542zM5.75 12.439v4.187c0 .763.379 1.441.999 1.785a21.14 21.14 0 0 0 2.59 1.227c.963.37 1.899.613 2.661.613s1.698-.243 2.662-.613a21.14 21.14 0 0 0 2.59-1.227c.62-.344.998-1.022.998-1.785v-4.187l-3.714 1.741a5.92 5.92 0 0 1-5.072 0zM3.41 9.684c-.403-.189-.66-.64-.66-1.184c0-.544.257-.995.66-1.184l6.691-3.137a4.42 4.42 0 0 1 3.798 0l6.691 3.137c.403.189.66.64.66 1.184c0 .544-.257.995-.66 1.184l-6.691 3.137a4.42 4.42 0 0 1-3.798 0z"
                                        clipRule="evenodd"
                                    ></path>
                                }
                                sideNavIsOpen={sideNavIsOpen}
                            >
                                <div>{m.SideHeader_courses()}</div>
                            </NewLinkNave>
                        </li>

                        <li>
                            <NewLinkNave
                                href="/articles"
                                page={page}
                                name="articles"
                                currentSVG={
                                    <>
                                        <path
                                            fill="currentColor"
                                            fillRule="evenodd"
                                            d="M14.25 4.48v3.057c0 .111 0 .27.021.406a.937.937 0 0 0 .444.683a.96.96 0 0 0 .783.072c.13-.04.272-.108.378-.159L17 8.005l1.124.534c.106.05.248.119.378.16a.958.958 0 0 0 .783-.073a.937.937 0 0 0 .444-.683c.022-.136.021-.295.021-.406V3.031c.113-.005.224-.01.332-.013C21.154 2.98 22 3.86 22 4.933v11.21c0 1.112-.906 2.01-2.015 2.08c-.97.06-2.108.179-2.985.41c-1.082.286-2.373.904-3.372 1.436c-.28.149-.575.257-.878.323V5.174a3.57 3.57 0 0 0 .924-.371c.184-.107.377-.216.576-.323m5.478 8.338a.75.75 0 0 1-.546.91l-4 1a.75.75 0 1 1-.364-1.456l4-1a.75.75 0 0 1 .91.546M11.25 5.214a3.444 3.444 0 0 1-.968-.339C9.296 4.354 8.05 3.765 7 3.487c-.887-.233-2.041-.352-3.018-.412C2.886 3.008 2 3.9 2 4.998v11.146c0 1.11.906 2.01 2.015 2.079c.97.06 2.108.179 2.985.41c1.081.286 2.373.904 3.372 1.436c.28.149.575.257.878.324zM4.273 8.818a.75.75 0 0 1 .91-.546l4 1a.75.75 0 1 1-.365 1.456l-4-1a.75.75 0 0 1-.545-.91m.91 3.454a.75.75 0 1 0-.365 1.456l4 1a.75.75 0 0 0 .364-1.456z"
                                            clipRule="evenodd"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            d="M18.25 3.151c-.62.073-1.23.18-1.75.336a8.2 8.2 0 0 0-.75.27v3.182l.75-.356l.008-.005a1.13 1.13 0 0 1 .492-.13c.048 0 .094.004.138.01c.175.029.315.1.354.12l.009.005l.75.356V3.647z"
                                        ></path>
                                    </>
                                }
                                notCurrentSVG={
                                    <>
                                        <path
                                            fill="currentColor"
                                            d="M19.728 12.818a.75.75 0 0 0-.91-.546l-4 1a.75.75 0 0 0 .364 1.456l4-1a.75.75 0 0 0 .546-.91M19.75 5.5a.75.75 0 0 0-1.5 0v3.439l-.75-.356l-.008-.005a1.13 1.13 0 0 0-.492-.13a1.13 1.13 0 0 0-.492.13l-.009.005l-.749.356V6.95a.75.75 0 0 0-1.5 0v2.587c0 .111 0 .27.02.406a.936.936 0 0 0 .445.683a.96.96 0 0 0 .783.072c.13-.04.272-.108.378-.159L17 10.005l1.124.534c.106.05.248.119.378.16a.957.957 0 0 0 .783-.073a.936.936 0 0 0 .444-.683c.021-.136.021-.295.021-.406zM4.272 8.818a.75.75 0 0 1 .91-.546l4 1a.75.75 0 1 1-.364 1.456l-4-1a.75.75 0 0 1-.546-.91m.91 3.454a.75.75 0 0 0-.364 1.456l4 1a.75.75 0 1 0 .364-1.456z"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            fillRule="evenodd"
                                            d="M22.75 4.933c0-1.463-1.165-2.718-2.694-2.665c-1.139.04-2.626.158-3.771.501c-.995.298-2.114.88-2.987 1.385a2.77 2.77 0 0 1-2.665.058c-.996-.526-2.305-1.15-3.442-1.45c-.96-.253-2.176-.375-3.163-.436C2.47 2.231 1.25 3.5 1.25 4.998v11.146c0 1.536 1.244 2.735 2.718 2.827c.959.06 2.038.176 2.84.388c.993.261 2.22.844 3.212 1.372a4.224 4.224 0 0 0 3.96 0c.991-.528 2.219-1.11 3.211-1.372c.803-.212 1.882-.328 2.841-.388c1.474-.092 2.718-1.291 2.718-2.827zm-2.642-1.166c.615-.021 1.142.485 1.142 1.166v11.21c0 .686-.568 1.285-1.312 1.331c-.98.062-2.179.183-3.13.434c-1.17.31-2.525.962-3.533 1.5c-.168.09-.344.16-.525.211V5.942c.452-.09.892-.254 1.3-.49c.85-.492 1.845-1 2.665-1.246c.95-.284 2.274-.4 3.393-.439M11.25 5.975a4.199 4.199 0 0 1-1.318-.436c-.977-.517-2.161-1.072-3.123-1.326c-.813-.215-1.907-.33-2.873-.39c-.634-.038-1.186.478-1.186 1.175v11.146c0 .685.568 1.284 1.312 1.33c.98.062 2.179.183 3.13.434c1.17.31 2.525.962 3.533 1.5c.168.09.345.16.525.211z"
                                            clipRule="evenodd"
                                        ></path>
                                    </>
                                }
                                sideNavIsOpen={sideNavIsOpen}
                            >
                                <div>{m.SideHeader_articles()}</div>
                            </NewLinkNave>
                        </li>

                        <li>
                            <NewLinkNave
                                href="https://discord.gg/believemy-749242783058886716"
                                page={page}
                                name="discord"
                                blank
                            >
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex items-center gap-3">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-5 h-5"
                                            viewBox="0 0 24 24"
                                        >
                                            <g
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                            >
                                                <path d="M5.5 16c5 2.5 8 2.5 13 0"></path>
                                                <path d="m15.5 17.5l1 2s4.171-1.328 5.5-3.5c0-1 .53-8.147-3-10.5c-1.5-1-4-1.5-4-1.5l-1 2h-2"></path>
                                                <path d="m8.528 17.5l-1 2s-4.171-1.328-5.5-3.5c0-1-.53-8.147 3-10.5c1.5-1 4-1.5 4-1.5l1 2h2"></path>
                                                <path d="M8.5 14c-.828 0-1.5-.895-1.5-2s.672-2 1.5-2s1.5.895 1.5 2s-.672 2-1.5 2m7 0c-.828 0-1.5-.895-1.5-2s.672-2 1.5-2s1.5.895 1.5 2s-.672 2-1.5 2"></path>
                                            </g>
                                        </svg>
                                        <div>{m.SideHeader_discord()}</div>
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12 1.25h-.057c-2.309 0-4.118 0-5.53.19c-1.444.194-2.584.6-3.479 1.494c-.895.895-1.3 2.035-1.494 3.48c-.19 1.411-.19 3.22-.19 5.529v.114c0 2.309 0 4.118.19 5.53c.194 1.444.6 2.584 1.494 3.479c.895.895 2.035 1.3 3.48 1.494c1.411.19 3.22.19 5.529.19h.114c2.309 0 4.118 0 5.53-.19c1.444-.194 2.584-.6 3.479-1.494c.895-.895 1.3-2.035 1.494-3.48c.19-1.411.19-3.22.19-5.529V12a.75.75 0 0 0-1.5 0c0 2.378-.002 4.086-.176 5.386c-.172 1.279-.5 2.05-1.069 2.62c-.57.569-1.34.896-2.619 1.068c-1.3.174-3.008.176-5.386.176s-4.086-.002-5.386-.176c-1.279-.172-2.05-.5-2.62-1.069c-.569-.57-.896-1.34-1.068-2.619c-.174-1.3-.176-3.008-.176-5.386s.002-4.086.176-5.386c.172-1.279.5-2.05 1.069-2.62c.57-.569 1.34-.896 2.619-1.068c1.3-.174 3.008-.176 5.386-.176a.75.75 0 0 0 0-1.5"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            d="M12.47 10.47a.75.75 0 1 0 1.06 1.06l7.72-7.72v3.534a.75.75 0 0 0 1.5 0V2a.75.75 0 0 0-.75-.75h-5.344a.75.75 0 0 0 0 1.5h3.533z"
                                        ></path>
                                    </svg>
                                </div>
                            </NewLinkNave>
                        </li>

                        <li className="after-line">
                            <div>{m.SideHeader_doneWithYou()}</div>
                        </li>

                        <li>
                            <NewLinkNave
                                href="/accelerators"
                                page={page}
                                name="accelerators"
                                currentSVG={
                                    <>
                                        <path
                                            fill="currentColor"
                                            d="M12.832 21.801c3.126-.626 7.168-2.875 7.168-8.69c0-5.291-3.873-8.815-6.658-10.434c-.619-.36-1.342.113-1.342.828v1.828c0 1.442-.606 4.074-2.29 5.169c-.86.559-1.79-.278-1.894-1.298l-.086-.838c-.1-.974-1.092-1.565-1.87-.971C4.461 8.46 3 10.33 3 13.11C3 20.221 8.289 22 10.933 22c.154 0 .316-.006.484-.015C10.111 21.874 8 21.064 8 18.444c0-2.05 1.495-3.435 2.631-4.11c.306-.18.663.055.663.41v.59c0 .45.175 1.155.59 1.637c.47.546 1.159-.026 1.214-.744c.018-.226.246-.37.442-.256c.641.375 1.46 1.175 1.46 2.473c0 2.048-1.129 2.99-2.168 3.357"
                                        ></path>
                                    </>
                                }
                                notCurrentSVG={
                                    <>
                                        <g
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                        >
                                            <path d="M20 13.111C20 20.222 13.956 22 10.933 22C8.29 22 3 20.222 3 13.111c0-2.782 1.461-4.65 2.86-5.716c.778-.594 1.77-.003 1.87.971l.086.838c.105 1.02 1.033 1.857 1.893 1.298C11.394 9.407 12 6.775 12 5.333V5.01c0-1.43 1.444-2.35 2.602-1.512C17.165 5.35 20 8.584 20 13.11Z"></path>
                                            <path d="M8 18.445C8 21.289 10.489 22 11.733 22c1.09 0 3.267-.711 3.267-3.555c0-1.102-.59-1.845-1.16-2.274c-.398-.299-.957-.03-1.094.449c-.178.624-.823 1.016-1.152.456c-.3-.512-.3-1.28-.3-1.743c0-.636-.64-1.048-1.155-.674C9.106 15.409 8 16.68 8 18.445Z"></path>
                                        </g>
                                    </>
                                }
                                sideNavIsOpen={sideNavIsOpen}
                            >
                                <div>{m.SideHeader_accelerators()}</div>
                            </NewLinkNave>
                        </li>

                        <li>
                            <NewLinkNave
                                href="/mentoring"
                                page={page}
                                name="mentors"
                                currentSVG={
                                    <>
                                        <path
                                            fill="currentColor"
                                            d="M12 2a5.75 5.75 0 0 0-5.75 5.75v3a5.75 5.75 0 0 0 11.452.75H13a.75.75 0 0 1 0-1.5h4.75V8.5H13A.75.75 0 0 1 13 7h4.701A5.751 5.751 0 0 0 12 2"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            fillRule="evenodd"
                                            d="M4 9a.75.75 0 0 1 .75.75v1a7.25 7.25 0 1 0 14.5 0v-1a.75.75 0 0 1 1.5 0v1a8.75 8.75 0 0 1-8 8.718v2.282a.75.75 0 0 1-1.5 0v-2.282a8.75 8.75 0 0 1-8-8.718v-1A.75.75 0 0 1 4 9"
                                            clipRule="evenodd"
                                        ></path>
                                    </>
                                }
                                notCurrentSVG={
                                    <>
                                        <g fill="none">
                                            <path
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                d="M7 8a5 5 0 0 1 10 0v3a5 5 0 0 1-10 0z"
                                            ></path>
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeWidth="1.5"
                                                d="M13 8h4m-4 3h4m-5 8v3"
                                            ></path>
                                            <path
                                                fill="currentColor"
                                                d="M20.75 10a.75.75 0 0 0-1.5 0zm-16 0a.75.75 0 0 0-1.5 0zm10.762 7.344a.75.75 0 1 0 .728 1.312zM19.25 10v1h1.5v-1zm-14.5 1v-1h-1.5v1zM12 18.25A7.25 7.25 0 0 1 4.75 11h-1.5A8.75 8.75 0 0 0 12 19.75zM19.25 11a7.248 7.248 0 0 1-3.738 6.344l.728 1.312A8.748 8.748 0 0 0 20.75 11z"
                                            ></path>
                                        </g>
                                    </>
                                }
                                sideNavIsOpen={sideNavIsOpen}
                            >
                                <div>{m.SideHeader_mentors()}</div>
                            </NewLinkNave>
                        </li>

                        <li className="after-line">
                            <div>{m.SideHeader_tools()}</div>
                        </li>

                        <li>
                            <NewLinkNave
                                href={
                                    locale == "fr"
                                        ? "/glossaries/html-et-css"
                                        : "/glossaries/html-and-css"
                                }
                                page={page}
                                name="glossaries"
                                currentSVG={
                                    <>
                                        <path
                                            fill="currentColor"
                                            fillRule="evenodd"
                                            d="M6.271 2.112c-.81.106-1.238.301-1.544.6c-.305.3-.504.72-.613 1.513C4.002 5.042 4 6.124 4 7.675v8.57a4.172 4.172 0 0 1 1.299-.593c.528-.139 1.144-.139 2.047-.138H20V7.676c0-1.552-.002-2.634-.114-3.451c-.109-.793-.308-1.213-.613-1.513c-.306-.299-.734-.494-1.544-.6c-.834-.11-1.938-.112-3.522-.112H9.793c-1.584 0-2.688.002-3.522.112m.488 4.483c0-.448.37-.811.827-.811h8.828a.82.82 0 0 1 .827.81a.82.82 0 0 1-.827.811H7.586a.82.82 0 0 1-.827-.81m.827 2.973a.82.82 0 0 0-.827.81c0 .448.37.811.827.811h5.517a.82.82 0 0 0 .828-.81a.82.82 0 0 0-.828-.811z"
                                            clipRule="evenodd"
                                        ></path>
                                        <path
                                            fill="currentColor"
                                            d="M8.69 17.135H7.473c-1.079 0-1.456.007-1.746.083a2.464 2.464 0 0 0-1.697 1.538c.016.382.043.719.084 1.019c.109.793.308 1.213.613 1.513c.306.299.734.494 1.544.6c.834.11 1.938.112 3.522.112h4.414c1.584 0 2.688-.002 3.522-.111c.81-.107 1.238-.302 1.544-.601c.305-.3.504-.72.613-1.513c.092-.666.11-1.51.113-2.64h-6.896v3.007c0 .298 0 .447-.104.507c-.105.06-.248-.007-.534-.14l-1.371-.638c-.097-.045-.145-.067-.197-.067c-.053 0-.101.022-.198.067l-1.37.638c-.287.133-.43.2-.535.14c-.104-.06-.104-.21-.104-.507z"
                                        ></path>
                                    </>
                                }
                                notCurrentSVG={
                                    <>
                                        <g
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                        >
                                            <path d="M4 8c0-2.828 0-4.243.879-5.121C5.757 2 7.172 2 10 2h4c2.828 0 4.243 0 5.121.879C20 3.757 20 5.172 20 8v8c0 2.828 0 4.243-.879 5.121C18.243 22 16.828 22 14 22h-4c-2.828 0-4.243 0-5.121-.879C4 20.243 4 18.828 4 16z"></path>
                                            <path d="M19.898 16h-12c-.93 0-1.395 0-1.777.102A3 3 0 0 0 4 18.224"></path>
                                            <path
                                                strokeLinecap="round"
                                                d="M8 7h8m-8 3.5h5m0 5.5v3.53c0 .276 0 .414-.095.47c-.095.056-.224-.006-.484-.13l-1.242-.59c-.088-.04-.132-.062-.179-.062c-.047 0-.091.021-.179.063l-1.242.59c-.26.123-.39.185-.484.129C9 19.944 9 19.806 9 19.53v-3.08"
                                            ></path>
                                        </g>
                                    </>
                                }
                                sideNavIsOpen={sideNavIsOpen}
                            >
                                <div className="flex items-center gap-3">
                                    {m.SideHeader_glossaries()}
                                    <span className="rounded-md px-1 bg-primary bg-opacity-30 text-primary uppercase text-[10px] font-bold tracking-wider">
                                        {m.SideHeader_new()}
                                    </span>
                                </div>
                            </NewLinkNave>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Assistance */}
            <div className="relative">
                {helpModalIsOpen && (
                    <div className="fixed bottom-10 left-0 md:w-[230px] w-[290px] m-3 z-50 shadow p-1.5 text-sm bg-white dark:bg-bgDark-550 rounded-lg">
                        <a href="mailto:hello@believemy.com?subject=Aide%20%26%20Suggestions">
                            <div className="bg-transparent hover:bg-primary hover:bg-opacity-10 text-primary text-opacity-100 text-sm flex items-center justify-between py-1.5 px-3 rounded-lg cursor-pointer">
                                {m.SideHeader_needHelp()}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M12 1.25h-.057c-2.309 0-4.118 0-5.53.19c-1.444.194-2.584.6-3.479 1.494c-.895.895-1.3 2.035-1.494 3.48c-.19 1.411-.19 3.22-.19 5.529v.114c0 2.309 0 4.118.19 5.53c.194 1.444.6 2.584 1.494 3.479c.895.895 2.035 1.3 3.48 1.494c1.411.19 3.22.19 5.529.19h.114c2.309 0 4.118 0 5.53-.19c1.444-.194 2.584-.6 3.479-1.494c.895-.895 1.3-2.035 1.494-3.48c.19-1.411.19-3.22.19-5.529V12a.75.75 0 0 0-1.5 0c0 2.378-.002 4.086-.176 5.386c-.172 1.279-.5 2.05-1.069 2.62c-.57.569-1.34.896-2.619 1.068c-1.3.174-3.008.176-5.386.176s-4.086-.002-5.386-.176c-1.279-.172-2.05-.5-2.62-1.069c-.569-.57-.896-1.34-1.068-2.619c-.174-1.3-.176-3.008-.176-5.386s.002-4.086.176-5.386c.172-1.279.5-2.05 1.069-2.62c.57-.569 1.34-.896 2.619-1.068c1.3-.174 3.008-.176 5.386-.176a.75.75 0 0 0 0-1.5"
                                    ></path>
                                    <path
                                        fill="currentColor"
                                        d="M12.47 10.47a.75.75 0 1 0 1.06 1.06l7.72-7.72v3.534a.75.75 0 0 0 1.5 0V2a.75.75 0 0 0-.75-.75h-5.344a.75.75 0 0 0 0 1.5h3.533z"
                                    ></path>
                                </svg>
                            </div>
                        </a>
                    </div>
                )}
                {languagesModalIsOpen && (
                    <div className="fixed bottom-10 left-0 md:w-[230px] w-[290px] p-1.5 m-3 z-50 shadow text-sm bg-white dark:bg-bgDark-550 rounded-lg">
                        <select
                            value={languageTag()}
                            onChange={(e) => updateLanguage(e.target.value)}
                            className="w-full cursor-pointer bg-transparent p-1.5 dark:text-white"
                        >
                            {availableLanguageTags.map((lang) => (
                                <option key={lang} value={lang}>
                                    {labels[lang]}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {/* New version */}
                <Link
                    href="https://discord.gg/believemy-749242783058886716"
                    target="_blank"
                >
                    <div className="rounded-full bg-black/90 text-white px-3 py-1 inline-block mx-3 md:text-sm">
                        ✨ {m.SideHeader_newVersionTitle()}
                    </div>
                    <div className="mt-1 md:text-xs text-sm text-lightBlue bg-black/80 px-1.5 py-1 mx-3 rounded-b-xl rounded-tr-xl">
                        {m.SideHeader_newVersionDescription()}
                    </div>
                </Link>

                {/* Experience */}
                {sessionLoading ? (
                    <div className="m-3">
                        <Skeleton height={30} />
                    </div>
                ) : (
                    <>
                        {session ? (
                            <div className="m-3 border-y dark:border-borderDark py-5">
                                <Level session={session} />
                            </div>
                        ) : (
                            <div className="m-3 border-y dark:border-borderDark py-5 flex flex-col gap-1">
                                <Link href="/signup">
                                    <Button type="pop" className="w-full" small>
                                        {m.SideHeader_createAccountButton()}
                                    </Button>
                                </Link>
                                <Link href="/login">
                                    <Button
                                        type="outline-confidence"
                                        className="w-full"
                                        small
                                    >
                                        {m.SideHeader_loginButton()}
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </>
                )}

                {/* Options */}
                <div className="flex gap-1">
                    {/* Help */}
                    <div
                        className="bg-white dark:bg-bgDark-300 dark:hover:bg-bgDark-550 flex-1 dark:text-textDark shadow rounded-lg px-3 py-1.5 flex items-center text-[#05060F] text-opacity-50 gap-2 text-sm cursor-pointer hover:bg-gray-100 duration-100 ml-3 mb-3 mt-3 border dark:border-borderDark"
                        onClick={() => {
                            setLanguagesModalIsOpen(false);
                            setHelpModalIsOpen(!helpModalIsOpen);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10m0-6a4 4 0 1 0 0-8a4 4 0 0 0 0 8"
                                clipRule="evenodd"
                                opacity=".5"
                            ></path>
                            <path
                                fill="currentColor"
                                d="m5.479 19.582l4.272-4.273a4.021 4.021 0 0 1-1.06-1.06L4.418 18.52c.327.38.681.734 1.06 1.06m-1.06-14.1l4.273 4.272a4.022 4.022 0 0 1 1.06-1.06L5.48 4.417c-.38.327-.734.682-1.061 1.061m9.829 3.213L18.52 4.42c.379.326.734.68 1.06 1.06l-4.27 4.272a4.021 4.021 0 0 0-1.061-1.06m5.332 9.829l-4.273-4.273a4.02 4.02 0 0 1-1.06 1.06l4.272 4.274c.38-.327.735-.682 1.061-1.061"
                            ></path>
                        </svg>
                        {m.SideHeader_needHelpButton()}
                    </div>

                    {/* Languages */}
                    <div
                        className="bg-white dark:bg-bgDark-300 dark:hover:bg-bgDark-550 dark:text-textDark shadow rounded-lg px-3 py-1.5 flex items-center text-[#05060F] text-opacity-50 gap-2 text-sm cursor-pointer hover:bg-gray-100 duration-100 mr-3 mb-3 mt-3 border dark:border-borderDark"
                        onClick={() => {
                            setHelpModalIsOpen(false);
                            setLanguagesModalIsOpen(!languagesModalIsOpen);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                        >
                            <g
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                color="currentColor"
                            >
                                <path d="M5 5.828h2.7m3.3 0H9.5m-1.8 0h1.8m-1.8 0V5m1.8.828c-.316 1.131-.98 2.201-1.736 3.141M5.836 11a19 19 0 0 0 1.928-2.03m0 0c-.385-.453-.925-1.184-1.08-1.515m1.08 1.514l1.157 1.203M13.5 19l.833-2m4.167 2l-.833-2m-3.334 0L16 13l1.667 4m-3.334 0h3.334"></path>
                                <path d="M14 10V8c0-2.828 0-4.243-.879-5.121C12.243 2 10.828 2 8 2s-4.243 0-5.121.879C2 3.757 2 5.172 2 8s0 4.243.879 5.121C3.757 14 5.172 14 8 14h2"></path>
                                <path d="M10 16c0-2.828 0-4.243.879-5.121C11.757 10 13.172 10 16 10s4.243 0 5.121.879C22 11.757 22 13.172 22 16s0 4.243-.879 5.121C20.243 22 18.828 22 16 22s-4.243 0-5.121-.879C10 20.243 10 18.828 10 16m-6 .5c0 1.404 0 2.107.337 2.611a2 2 0 0 0 .552.552C5.393 20 6.096 20 7.5 20M20 7.5c0-1.404 0-2.107-.337-2.611a2 2 0 0 0-.552-.552C18.607 4 17.904 4 16.5 4"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}
