import React, { createContext, useContext } from "react";

const ExtraPropContext = createContext();

export const ExtraPropProvider = ({ extraProp, children }) => {
    return (
        <ExtraPropContext.Provider value={extraProp}>
            {children}
        </ExtraPropContext.Provider>
    );
};

export const useExtraProp = () => {
    return useContext(ExtraPropContext);
};
