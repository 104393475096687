import { Link } from "@/lib/i18n";

export default function NewLinkNave({
    children,
    href,
    page,
    name,
    currentSVG,
    notCurrentSVG,
    blank = false,
    sideNavIsOpen: setSideNavIsOpen = () => {},
}) {
    const handleClick = () => {
        if (setSideNavIsOpen) {
            setSideNavIsOpen(false);
        }
    };

    return (
        <Link
            href={href}
            className={`sidenav-link ${
                page == name && "font-bold !text-opacity-100"
            }`}
            target={blank ? "_blank" : "_self"}
            onClick={handleClick}
        >
            {currentSVG && notCurrentSVG && (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                    >
                        {page == name ? currentSVG : notCurrentSVG}{" "}
                    </svg>
                </>
            )}
            {children}
        </Link>
    );
}
